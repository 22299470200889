@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

$body-color: rgba(192, 184, 171, 1);
$white: rgba(255, 255, 255, 1);

$font-weight-light: 400;

$h1-font-size: 36px;
$h2-font-size: 30px;

$headings-font-weight: 700;

$font-family-base: 'eurostile', sans-serif;
$headings-font-family: 'eurostile', serif;

$bottomTransformFix: -400px;

$boxShadowMobile: 10px 10px 50px 0 rgba(0, 0, 0, 0.5);
$boxShadowDesktop: 33px 40px 75px 0 rgba(0, 0, 0, 0.5);
