@import url("https://use.typekit.net/ukr0wjb.css");

@import "./variables";

@import "node_modules/bootstrap/scss/bootstrap";

body {
  overflow-x: hidden;
}

#logo {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 9999;
  width: 75px;

  @include media-breakpoint-up("md") {
    width: 120px;
  }
}

#selectLanguage {
  position: absolute;
  z-index: 9;
  margin: 0;
  top: 15px;
  right: 15px;

  li {
    a {
      display: block;
      height: 25px;
      box-shadow: 33px 40px 75px 0 rgba(0, 0, 0, 0.5);

      img {
        max-height: 100%;
      }
    }
  }
}

#first_section {
  background-image: url("../gfx/bg/first_section.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  .gradiant {
    background: linear-gradient(
                    to bottom,
                    rgba(52, 52, 52, 0.75) 0%,
                    rgb(52, 52, 52) 100%
    );
    height: 100%;
    min-height: 100vh;
    position: relative;
    padding-top: 100px;

    @include media-breakpoint-up("md") {
      padding-top: 50px;
    }

    .container {
      height: 100%;
      flex-direction: column;

      .row {
        flex: 1 0 auto;
        align-items: center;

        .content {
          span {
            font-size: 16px;

            @include media-breakpoint-up("md") {
              font-size: 20px;
            }

            @include media-breakpoint-up("xl") {
              font-size: 24px;
            }
          }

          p {
            color: $white;
          }

          figure {
            margin-bottom: $grid-gutter-width;

            @include media-breakpoint-up("md") {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .wave_wrapper {
      width: 100%;
      position: absolute;
      bottom: 0;

      svg {
        display: block;

        path {
          fill: $body-bg;
        }
      }
    }
  }
}

#second_section {
  @include media-breakpoint-up("xl") {
    padding-top: 100px;
  }

  .container {
    .row {
      p {
        color: rgb(52, 52, 52);
      }

      h2 {
        color: rgb(192, 184, 171);
      }

      margin-bottom: 100px;

      @include media-breakpoint-up("md") {
        margin-bottom: 200px;

        &:last-child {
          margin-bottom: 100px;
        }
      }

      @include media-breakpoint-up("xl") {
        margin-bottom: 300px;

        &:last-child {
          margin-bottom: 100px;
        }
      }
    }
  }

  #schwarzwald_slider_image {
    box-shadow: $boxShadowMobile;
    margin: 0 auto;
    margin-bottom: $grid-gutter-width;
    margin-top: -75px;
    position: relative;

    @include media-breakpoint-up("md") {
      transform: translate(0, -10vw);
      right: auto;
      left: 0;
      margin: 0;
      position: absolute;
      max-width: 865px;
      box-shadow: $boxShadowDesktop;
      width: calc(50vw - #{(map-get($container-max-widths, "md") / 12)});

      /* Landscape */
      @media screen and (orientation: landscape) {
        width: calc(
                50vw - #{(map-get($container-max-widths, 'md') / 12)} - 20px
        );
      }
    }

    @include media-breakpoint-up("xl") {
      width: calc(50vw - #{(map-get($container-max-widths, "xl") / 12)});
    }
  }
}

figure {
  &.big-left {
    box-shadow: $boxShadowMobile;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: -75px;
    position: relative;

    @include media-breakpoint-up("md") {
      left: -$grid-gutter-width/2;
      transform: translate(0, -10vw);
      right: 0;
      left: auto;
      margin: 0;
      position: absolute;
      max-width: 865px;
      box-shadow: $boxShadowDesktop;
      width: calc(
              50vw - #{(map-get($container-max-widths, 'md') / 12) - $grid-gutter-width}
      );
    }

    @include media-breakpoint-up("xl") {
      width: calc(
              50vw - #{(map-get($container-max-widths, 'xl') / 12) - $grid-gutter-width}
      );
    }
  }
}

#third_section {
  background-image: url("../gfx/bg/third_section.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .gradiant {
    background: linear-gradient(
                    to bottom,
                    rgba(52, 52, 52, 0.75) 0%,
                    rgba(52, 52, 52, 1) 100%
    );
    height: 100%;
    min-height: 700px;
    position: relative;

    @include media-breakpoint-up("md") {
      min-height: 500px;
    }

    .wave_wrapper {
      width: 100%;
      position: absolute;
      top: 0;

      svg {
        display: block;

        path {
          fill: $body-bg;
        }
      }
    }

    .container {
      padding-top: 50px;
      padding-bottom: 700px;

      @include media-breakpoint-up("md") {
        padding-top: 100px;
        padding-bottom: 400px;
      }

      @include media-breakpoint-up("xl") {
        padding-bottom: 800px;
      }

      p {
        color: $white;
      }

      a {
        &:hover {
          color: darken($body-color, 10%);
        }
      }

      #history_wrapper {
        margin-bottom: 150px;
        position: relative;

        @include media-breakpoint-up("md") {
          padding: 50px 0;
          margin-bottom: 100px;
        }

        @include media-breakpoint-up("xl") {
          margin-bottom: 350px;
        }

        p {
          margin: 0;
        }

        .content {
          background-color: rgba(64, 64, 64, 1);
          box-shadow: $boxShadowMobile;
          padding: $grid-gutter-width/2;
          margin: 15px;

          @include media-breakpoint-up("md") {
            box-shadow: $boxShadowDesktop;
            padding: 42px 0;
            margin-bottom: 100px;
          }

          @include media-breakpoint-up("xl") {
            margin-bottom: 150px;
          }

          figure {
            box-shadow: $boxShadowMobile;
            border: 1px solid $body-color;
            margin-bottom: $grid-gutter-width/2;

            @include media-breakpoint-up("md") {
              box-shadow: $boxShadowDesktop;
              margin: 0 -30px 0 30px;
            }

            @include media-breakpoint-up("xl") {
              box-shadow: $boxShadowDesktop;
              margin: 0;
            }
          }

          a {
            &:hover {
              color: darken($body-color, 10%);
            }
          }
        }
      }
    }
  }
}

#fourth_section {
  background-image: url("../gfx/bg/fourth_section.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

  .gradiant {
    background: linear-gradient(
                    to bottom,
                    rgba(192, 184, 171, 0.75) 0%,
                    rgba(192, 184, 171, 1) 100%
    );
    height: 100%;
    position: relative;

    .wave_wrapper {
      width: 100%;
      position: absolute;
      top: 0;

      svg {
        display: block;

        path {
          fill: rgba(52, 52, 52, 1);
        }
      }
    }

    .container {
      padding-bottom: $grid-gutter-width/2;

      @include media-breakpoint-up("md") {
        padding-bottom: 150px;
      }

      @include media-breakpoint-up("xl") {
        padding-bottom: 350px;
      }

      #info_wrapper {
        padding: $grid-gutter-width;
        background-color: rgba(64, 64, 64, 1);
        box-shadow: 0 15px 75px -10px rgba(0, 0, 0, 0.5);
        margin-top: -675px;

        @include media-breakpoint-up("md") {
          background-color: rgba(52, 52, 52, 1);
          padding: 100px $grid-gutter-width/2;
          margin-top: -375px;
          margin-bottom: 150px;
        }

        @include media-breakpoint-up("xl") {
          margin-top: -475px;
          margin-bottom: 350px;
        }

        p {
          margin-bottom: 50px;
          color: $white;

          @include media-breakpoint-up("md") {
            margin-bottom: 100px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          a {
            &:hover {
              color: darken($body-color, 10%);
            }
          }
        }
      }

      .data-policy {
        h1, h2, h3, h4, h5 {
          color: $body-color;
        }

        color: white;

        ul {
          color: white;
        }
      }

      #maps_wrapper {
        padding-top: $grid-gutter-width;
        position: relative;
        color: $white;

        @include media-breakpoint-up("md") {
          padding: 50px 0;
        }

        h2 {
          &:after {
            background-color: $white;
          }
        }

        a {
          &:hover {
            color: darken($white, 10%);
          }
        }

        .content {
          background-color: rgba(192, 184, 171, 1);
          box-shadow: $boxShadowMobile;
          padding: $grid-gutter-width/2;
          margin: 15px;
          padding-top: 260px;

          @include media-breakpoint-up("md") {
            box-shadow: $boxShadowDesktop;
            padding: 42px 0;
            margin: 0;
          }
        }

        .map_wrapper {
          position: absolute;
          width: 100%;
          height: 400px;
          pointer-events: none;
          top: 75px;

          @include media-breakpoint-up("md") {
            height: 100%;
            top: 0;
          }

          > .row {
            height: 100%;

            #map {
              position: absolute;
              pointer-events: all;
              border: 1px solid rgba(151, 151, 151, 1);
              background-color: rgba(216, 216, 216, 1);
              box-shadow: $boxShadowMobile;
              top: 0;
              right: $grid-gutter-width/2;
              bottom: 0;
              left: $grid-gutter-width/2;

              @include media-breakpoint-up("md") {
                box-shadow: $boxShadowDesktop;
                height: 100%;
                right: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}

h1 {
  color: $white;
  margin: 0;
  font-size: 30px;
  line-height: 45px;

  @include media-breakpoint-up("md") {
    font-size: 45px;
    line-height: 60px;
  }

  @include media-breakpoint-up("xl") {
    font-size: 63px;
    line-height: 80px;
  }

  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 125px;
    background-color: $body-color;
    transform: translate(-25%, 0);
    transform-origin: 50% 50%;
    margin: 30px 0;
  }

  &::selection {
    background: rgb(52, 52, 52);
  }
}

h2 {
  margin: 0;
  line-height: 40px;

  @include media-breakpoint-up("md") {
    font-size: 40px;
    line-height: 50px;
  }

  @include media-breakpoint-up("xl") {
    font-size: 60px;
    line-height: 80px;
  }

  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 50px;
    background-color: $body-color;
    transform: translate(-10%, 0);
    margin: $grid-gutter-width/2 0;

    @include media-breakpoint-up("md") {
      margin: $grid-gutter-width 0;
      transform: translate(-25%, 0);
      width: 125px;
    }
  }

  &::selection {
    background: rgb(52, 52, 52);
  }
}

p {
  line-height: 1.56;
}

a {
  color: inherit;
  @include transition($transition-base);
  text-decoration: underline;
}

footer {
  padding: $grid-gutter-width;

  color: rgb(64, 64, 64);

  ul {
    margin-bottom: 0;

    li {
      &.list-inline-item {
        a {
          color: inherit;
          text-decoration: none;

          &:hover {
            color: $body-color;
            text-decoration: none;
          }
        }
      }
    }
  }
}

figure {
  margin: 0;
}

#impressum {
  #fourth_section {
    .gradiant {
      .container {
        padding-bottom: 0px;

        #info_wrapper {
          margin-bottom: 25px;

          p {
            margin-bottom: 25px;
          }

          table {
            color: $white;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

::selection {
  background: #c0b8ab;
}

#instafeed {
  margin-top: 50px;
  margin-bottom: 0;

  @include media-breakpoint-up("md") { 
    margin-bottom: 100px;
  }

  a {
    box-shadow: 33px 40px 75px 0 rgba(0, 0, 0, 0.5);

    img {
      max-width: 100%;
      border: 8px solid white;
      @include transition($transition-base);
    }

    &:hover {
      img {
        border-width: 5px;
      }
    }
  }
}
